import React from 'react'
import {
  BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClipLoader, ClockLoader,
  DotLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PacmanLoader, PropagateLoader, PuffLoader, PulseLoader, RingLoader,
  RiseLoader, RotateLoader, ScaleLoader, SyncLoader
} from 'react-spinners';

const Loading = (props) => (
  <div className={'sweet-loading ' + props.class}>
    {props.type === 'controls' ?
      <BeatLoader
        className={props.loaderClass ? props.loaderClass : "override"}
        margin={"1px"}
        size={8}
        color={'#123abc'}
        loading={props.enable}
      />
      : props.type === 'child' ?
        <BarLoader
          className={props.loaderClass ? props.loaderClass : "override"}
          height={10}
          width={40}
          color={'#dcdfe4'}
          loading={props.enable}
        />
        : props.token && props.token.menu ?
          (
            props.token.menu.Loader == 'BarLoader' ?
              <BarLoader
                className={props.loaderClass ? props.loaderClass : "override"}
                height={props.token.menu.Height}
                width={props.token.menu.Width}
                color={props.token.menu.color}
                // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                speedMultiplier={props.token.menu.speedMultiplier}
                loading={props.enable}
              />
              : props.token.menu.Loader == 'ClockLoader' ?
                <ClockLoader
                  className={props.loaderClass ? props.loaderClass : "override"}
                  size={props.token.menu.Size ? props.token.menu.Size : 35}
                  color={props.token.menu.color}
                  // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                  speedMultiplier={props.token.menu.speedMultiplier}
                  loading={props.enable}
                /> : props.token.menu.Loader == 'BeatLoader' ?
                  <BeatLoader
                    className={props.loaderClass ? props.loaderClass : "override"}
                    margin={props.token.menu.Margin}
                    size={props.token.menu.Size ? props.token.menu.Size : 35}
                    color={props.token.menu.color}
                    // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                    speedMultiplier={props.token.menu.speedMultiplier}
                    loading={props.enable}
                  />
                  : props.token.menu.Loader == 'BounceLoader' ?
                    <BounceLoader
                      className={props.loaderClass ? props.loaderClass : "override"}
                      size={props.token.menu.Size ? props.token.menu.Size : 35}
                      color={props.token.menu.color}
                      // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                      speedMultiplier={props.token.menu.speedMultiplier}
                      loading={props.enable}
                    />
                    : props.token.menu.Loader == 'CircleLoader' ?
                      <CircleLoader
                        className={props.loaderClass ? props.loaderClass : "override"}
                        size={props.token.menu.Size ? props.token.menu.Size : 35}
                        color={props.token.menu.color}
                        // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                        speedMultiplier={props.token.menu.speedMultiplier}
                        loading={props.enable}
                      />
                      : props.token.menu.Loader == 'ClimbingBoxLoader' ?
                        <ClimbingBoxLoader
                          className={props.loaderClass ? props.loaderClass : "override"}
                          size={props.token.menu.Size ? props.token.menu.Size : 35}
                          color={props.token.menu.color}
                          // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                          speedMultiplier={props.token.menu.speedMultiplier}
                          loading={props.enable}
                        />
                        : props.token.menu.Loader == 'ClipLoader' ?
                          <ClipLoader
                            className={props.loaderClass ? props.loaderClass : "override"}
                            size={props.token.menu.Size ? props.token.menu.Size : 35}
                            color={props.token.menu.color}
                            // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                            speedMultiplier={props.token.menu.speedMultiplier}
                            loading={props.enable}
                          />
                          : props.token.menu.Loader == 'DotLoader' ?
                            <DotLoader
                              className={props.loaderClass ? props.loaderClass : "override"}
                              margin={props.token.menu.Margin}
                              size={props.token.menu.Size ? props.token.menu.Size : 35}
                              color={props.token.menu.color}
                              // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                              speedMultiplier={props.token.menu.speedMultiplier}
                              loading={props.enable}
                            />
                            : props.token.menu.Loader == 'FadeLoader' ?
                              <FadeLoader
                                className={props.loaderClass ? props.loaderClass : "override"}
                                margin={props.token.menu.Margin}
                                height={props.token.menu.Height}
                                width={props.token.menu.Width}
                                radius={props.token.menu.Radius}
                                color={props.token.menu.color}
                                // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                speedMultiplier={props.token.menu.speedMultiplier}
                                loading={props.enable}
                              />
                              : props.token.menu.Loader == 'GridLoader' ?
                                <GridLoader
                                  className={props.loaderClass ? props.loaderClass : "override"}
                                  size={props.token.menu.Size ? props.token.menu.Size : 35}
                                  color={props.token.menu.color}
                                  // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                  speedMultiplier={props.token.menu.speedMultiplier}
                                  loading={props.enable}
                                />
                                : props.token.menu.Loader == 'HashLoader' ?
                                  <HashLoader
                                    className={props.loaderClass ? props.loaderClass : "override"}
                                    margin={props.token.menu.Margin}
                                    size={props.token.menu.Size ? props.token.menu.Size : 35}
                                    color={props.token.menu.color}
                                    // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                    speedMultiplier={props.token.menu.speedMultiplier}
                                    loading={props.enable}
                                  />
                                  : props.token.menu.Loader == 'MoonLoader' ?
                                    <MoonLoader
                                      className={props.loaderClass ? props.loaderClass : "override"}
                                      margin={props.token.menu.Margin}
                                      size={props.token.menu.Size ? props.token.menu.Size : 35}
                                      color={props.token.menu.color}
                                      // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                      speedMultiplier={props.token.menu.speedMultiplier}
                                      loading={props.enable}
                                    />
                                    : props.token.menu.Loader == 'PacmanLoader' ?
                                      <PacmanLoader
                                        className={props.loaderClass ? props.loaderClass : "override"}
                                        margin={props.token.menu.Margin}
                                        size={props.token.menu.Size ? props.token.menu.Size : 35}
                                        color={props.token.menu.color}
                                        // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                        speedMultiplier={props.token.menu.speedMultiplier}
                                        loading={props.enable}
                                      />
                                      : props.token.menu.Loader == 'PropagateLoader' ?
                                        <PropagateLoader
                                          className={props.loaderClass ? props.loaderClass : "override"}
                                          size={props.token.menu.Size ? props.token.menu.Size : 35}
                                          color={props.token.menu.color}
                                          // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                          speedMultiplier={props.token.menu.speedMultiplier}
                                          loading={props.enable}
                                        />
                                        :
                                        props.token.menu.Loader == 'PuffLoader' ?
                                          <PuffLoader
                                            className={props.loaderClass ? props.loaderClass : "override"}
                                            size={props.token.menu.Size}
                                            color={props.token.menu.color}
                                            // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                            speedMultiplier={props.token.menu.speedMultiplier}
                                            loading={props.enable}
                                          />
                                          :
                                          props.token.menu.Loader == 'PulseLoader' ?
                                            <PulseLoader
                                              className={props.loaderClass ? props.loaderClass : "override"}
                                              margin={props.token.menu.Margin}
                                              size={props.token.menu.Size ? props.token.menu.Size : 35}
                                              color={props.token.menu.color}
                                              // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                              speedMultiplier={props.token.menu.speedMultiplier}
                                              loading={props.enable}
                                            /> : props.token.menu.Loader == 'RingLoader' ?
                                              <RingLoader
                                                className={props.loaderClass ? props.loaderClass : "override"}
                                                margin={props.token.menu.Margin}
                                                size={props.token.menu.Size ? props.token.menu.Size : 35}
                                                color={props.token.menu.color}
                                                // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                                speedMultiplier={props.token.menu.speedMultiplier}
                                                loading={props.enable}
                                              /> :
                                              props.token.menu.Loader == 'RiseLoader' ?
                                                <RiseLoader
                                                  className={props.loaderClass ? props.loaderClass : "override"}
                                                  margin={props.token.menu.Margin}
                                                  size={props.token.menu.Size ? props.token.menu.Size : 35}
                                                  color={props.token.menu.color}
                                                  // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                                  speedMultiplier={props.token.menu.speedMultiplier}
                                                  loading={props.enable}
                                                /> :
                                                props.token.menu.Loader == 'RotateLoader' ?
                                                  <RotateLoader
                                                    className={props.loaderClass ? props.loaderClass : "override"}
                                                    margin={props.token.menu.Margin}
                                                    size={props.token.menu.Size ? props.token.menu.Size : 35}
                                                    color={props.token.menu.color}
                                                    // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                                    speedMultiplier={props.token.menu.speedMultiplier}
                                                    loading={props.enable}
                                                  /> :
                                                  props.token.menu.Loader == 'SyncLoader' ?
                                                    <SyncLoader
                                                      className={props.loaderClass ? props.loaderClass : "override"}
                                                      margin={props.token.menu.Margin ? props.token.menu.Margin : 2}
                                                      size={props.token.menu.Size ? props.token.menu.Size : 15}
                                                      color={props.token.menu.color ? props.token.menu.color : '#757575'}
                                                      // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                                      speedMultiplier={props.token.menu.speedMultiplier ? props.token.menu.speedMultiplier : 1}
                                                      loading={props.enable}
                                                    /> : props.token.menu.Loader == 'ScaleLoader' ?
                                                      <ScaleLoader
                                                        className={props.loaderClass ? props.loaderClass : "override"}
                                                        margin={props.token.menu.Margin}
                                                        height={props.token.menu.Height}
                                                        width={props.token.menu.Width}
                                                        radius={props.token.menu.Radius}
                                                        color={props.token.menu.color}
                                                        // cssOverride={props.token.menu.cssOverride ? JSON.parse(props.token.menu.cssOverride) : {}}
                                                        speedMultiplier={props.token.menu.speedMultiplier}
                                                        loading={props.enable} />
                                                      : props.token.menu.Loader == 'CustomImage' ?
                                                        props.enable &&
                                                        <img className="mb-3" width={props.token.menu.Width ? props.token.menu.Width : 49} height={props.token.menu.Height ? props.token.menu.Height : 47} alt={""} src={props.token.menu.loaderimg} />
                                                        :
                                                        <CircleLoader
                                                          className={props.loaderClass ? props.loaderClass : "override"}
                                                          // radius={2}
                                                          // margin={"2px"}
                                                          size={50}
                                                          color={'#757575'}
                                                          loading={props.enable}
                                                        />

          )
          : <CircleLoader
            className={props.loaderClass ? props.loaderClass : "override"}
            // radius={2}
            // margin={"2px"}
            size={50}
            color={'#757575'}
            loading={props.enable}
          />
    }
  </div>
)
export default Loading 
