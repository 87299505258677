// import firebaseService from './services/firebase'; /** Uncomment when aws login required */
import cosmosService from './services/cosmos';
// import awsService from './services/aws'; /** Uncomment when aws login required */
import storageService from './services/storage';
import azureFunction from './services/azure';
import httpService from './services/http';
import { settings } from '../config/settings'
import authService from "./services/auth";
import ControlService from "../helper/controlservice";
// import AzureAuthService from "../helper/auth/Azure/AzureAuthService";
// import { azureProvider } from '../helper/auth/Azure/configure';
// import * as Msal from '@azure/msal-browser';

var retryCall = 1;
const utilily = resource => {
  let reConcileUrl = (url, Token, params) => {
    if (url) {
      if (url.toLowerCase().indexOf('api/appdata') > -1
        || url.toLowerCase().indexOf('api/application') > -1) {
        let user = Token && Token.user ? Token.user : {};
        if (user.ActiveApp && !params['idx']) {
          params['idx'] = user.ActiveApp.database;
        }
      }
    }
    else {
      url = 'api/controller/NoApi';
    }
    return url;
  }
  let reConcileModel = (method, payload, url, params, model) => {
    if (method && payload) {
      if ((method == "post" && url.toLowerCase().trim() == "api/application/poco") || method == "poco") {
        if (typeof payload === "string") {
          try {
            payload = JSON.parse(payload);
          } catch (e) {
            return Promise.resolve({ status: "error", message: "Incorrect Json Format" });
          }
        }
        if (payload) {
          model = payload;
        }
      }
    }
    return model;
  }
  let GetConnectionById = (id, connectionslist) => {
    if (id) {
      let connection = Array.isArray(connectionslist) ? connectionslist.filter(x => x.id == id) : [];
      if (Array.isArray(connection) && connection.length > 0) {
        return connection[0];
      } else {
        if (id == 'AZURE_FUNCTION_ENDPOINT') {
          return { baseurl: settings.REACT_APP_AZURE_FUNCTION_ENDPOINT }
        } else
          return {};
      }
    } else return {};
  };
  let servicerequest = async (control,
    url,
    params,
    model,
    Token,
    metadata, callback) => {
    var datasource = control.datasource ? control.datasource : "http"
    var method = control.method
    var headers = control.headers
    var connectionid = control.connection
    var modelname = control.model
    var cosmosquery = control.query
    var payload = control.payload
    var ResultApiParam = control.ResultApiParam || control.apiparam || control.FilterEndpointParams
    var realtime = control.realtime

    var connection = {};
    // Fetch connection object 
    if (connectionid && Token) {
      connection = GetConnectionById(connectionid, Token.dataconnection);
    }
    // Model and payload model check for poco 
    model = reConcileModel(method, payload, url, params, model);

    // Encypt payload
    if (control.enableencryption && model) {
      model = ControlService().encryptData(model);
    }

    if (Array.isArray(control.dupescheck) && control.dupescheck.length > 0) {
      params['dupescheck'] = JSON.stringify(control.dupescheck);
    }
    if (datasource == settings.DATASOURCE.COSMOSDB || datasource == settings.DATASOURCE.SQLDB || datasource == settings.DATASOURCE.MYSQLDB || datasource == settings.DATASOURCE.DYNAMODB) {
      var sprocname = control.sprocname
      return cosmosService({
        datasource,
        model,
        modelname,
        connection,
        cosmosquery,
        method,
        params,
        sprocname, Token, control
      }).call();
    } else if (datasource == settings.DATASOURCE.AZUREFUNCTION) {
      // assigning noapi if api not entered or metadata api is calling
      url = reConcileUrl(url, Token, params);

      return azureFunction({
        url,
        model,
        Token,
        headers,
        connection,
        method, control
      }).call()
    } else if (datasource == settings.DATASOURCE.FIREBASE) {
      return null; /** Uncomment when firebase login required */
      // return firebaseService({
      //   model,
      //   modelname,
      //   connection,
      //   Token,
      //   ResultApiParam,
      //   realtime,
      //   method,
      //   params,
      //   control,
      //   callback
      // }).call();
    }
    // else if (datasource == settings.DATASOURCE.DYNAMODB) {
    //   return null; /** Uncomment when aws login required */
    // return awsService({
    //   model,
    //   modelname,
    //   connection,
    //   Token,
    //   ResultApiParam,
    //   realtime,
    //   method,
    //   params,
    //   datasource,
    //   metadata
    // }).call();
    // }
    // else if (datasource == settings.DATASOURCE.S3) {
    //   return storageService({
    //     model,
    //     modelname,
    //     connection,
    //     Token,
    //     ResultApiParam,
    //     realtime,
    //     method,
    //     params,
    //     datasource,
    //     metadata,
    //     control
    //   }).call_S3();
    // } 
    else if (datasource == settings.DATASOURCE.S3 || datasource == settings.DATASOURCE.BLOB || datasource == settings.DATASOURCE.GITHUB) {
      return storageService({
        model,
        modelname,
        connection,
        Token,
        method,
        params,
        datasource
      }).call_AzBlob();
    } else if (datasource == settings.DATASOURCE.FIREBASESTORE) {
      return storageService({
        model,
        modelname,
        connection,
        Token,
        ResultApiParam,
        realtime,
        method,
        params,
        datasource,
        metadata,
        control
      }).call_Firebase();
    } else {
      // assigning noapi if api not entered or metadata api is calling
      url = reConcileUrl(url, Token, params);

      return httpService({
        url,
        params,
        model,
        Token,
        headers,
        connection,
        method,
        datasource,
        control
      }).call()
    }
  },
    servicerequest_callback = async (control,
      url,
      params,
      model,
      Token,
      metadata, callback) => {
      return await servicerequest(control,
        url,
        params,
        model,
        Token,
        metadata, callback).then(async (response) => {

          if (response && response.statusCode === 401) {
            let state = authService().loadState();
            if (state.formLoginReducer.token.accesstoken !== Token.accesstoken) {
              Token = state.formLoginReducer.token;
            }
            if (retryCall <= 3 && (settings.LOGIN_SERVICE === 'azureb2c' || settings.LOGIN_SERVICE === 'azuread') && !ControlService().isTokenValid(state.formLoginReducer.token)) {
              retryCall = retryCall + 1;
              return servicerequest_callback(control,
                url,
                params,
                model,
                Token,
                metadata, callback);
            }
          }
          return response;
        })
    }
  return {
    servicerequest: async (control,
      url,
      params,
      model,
      Token,
      metadata, callback) => {
      retryCall = 1;
      return await servicerequest_callback(control,
        url,
        params,
        model,
        Token,
        metadata, callback);
    }
  };
};

export default utilily;

