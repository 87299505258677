import React, { Suspense } from 'react';
import utilily from "../../utils";
import { settings } from "../../config/settings";
import { toast } from 'react-toastify';
import { setPageVariablesFromMenu, _isTokenValid, submitAction, ServiceRequest, SetParamUrl, addbookmark, loggedInDataSource, isGuid, isPhoneNumber, isWebAddress, GetParamUrl, find, setPageVariables, GenerateDataJson, deserializeForm } from "./_private";
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as moment from 'moment-timezone';
import EncryptionService from '../EncryptionService';

const uuidv4 = require('uuid/v4');
const _ = require('lodash');
const DynamicFormControls = React.lazy(() => import("../../forms/DynamicForm/DynamicFormControls"));
const Message = React.lazy(() => import('../component/Message'));
const Loading = React.lazy(() => import('../component/Loading'));

var MetadataStream = [];
var isTokenResfreshing = false;
const ControlService = () => {
  return {
    numberToCommas: function (value) {
      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    },
    isGitPublishApi: function (url) {
      return url.toLowerCase().includes('api/github/publish');
    },
    isGitCrendentialApi: function (url) {
      return url.toLowerCase().includes('api/github/crendential');
    },
    setPageVariablesFromMenu: function (ResultApiParam, apiparam, token, model, props) {
      return setPageVariablesFromMenu(ResultApiParam, apiparam, token, model ? model : {}, props);
    },
    ApiResponse: function (res) {
      if (Array.isArray(res)) {
        let arr = {};
        arr.data = res.length > 0 ? res[0] : {};
        arr.status = "success";
        res = arr;
      } else if (res && res.status != "error" && (res.statusCode == 201 || res.statusCode == 200 || !res.statusCode)) {
        res.status = "success"
      } else {
        res.status = "error"
      }

      return res;
    },
    azureFunctionUrl: function (Token) {
      if (Token && Token.AzureFunctionEndpoint) {
        return Token.AzureFunctionEndpoint;
      } else {
        return settings.REACT_APP_AZURE_FUNCTION_ENDPOINT;
      }
    },
    decodeRoles: function (roles) {
      if (Array.isArray(roles)) {
        return roles;
      } else {
        try {
          return JSON.parse(roles);
        } catch {
          return roles ? [roles] : [];
        }
      }
    },
    isTokenResfreshing: function () {
      return isTokenResfreshing;
    },
    setTokenResfreshing: function (bool) {
      isTokenResfreshing = bool;
    },
    toast: function (type, message) {
      toast.success(<Message message={message} type={type} />)
    },
    toastSuccess: function (message, title, icon) {
      toast.success(<Message message={message} title={title} icon={icon} />)
    },
    toastError: function (message, title, icon) {
      toast.error(<Message message={message} title={title} icon={icon} />)
    },
    toastInfo: function (message, title, icon) {
      toast.info(<Message message={message} title={title} icon={icon} />)

    },
    toastWarn: function (message, title, icon) {
      toast.warn(<Message message={message} title={title} icon={icon} />)
    },
    renderIf: function (condition, content) {
      if (condition) {
        return content;
      } else {
        return null;
      }
    },
    UUID: function () {
      return uuidv4();
    },
    capitalize: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    pad: function (num, size) {
      let s = String(num);
      while (s.length < size) {
        s = "0" + s;
      }
      return s;
    },
    parseDateString: function (dateString, includeTime = true, utc = true) {
      if (!dateString) {
        return {
          year: -1,
          month: -1,
          day: -1,
          hour: includeTime ? -1 : 0,
          minute: includeTime ? -1 : 0,
          second: includeTime ? -1 : 0,
        };
      }
      const date = new Date(dateString);
      if (Number.isNaN(date.getTime())) {
        throw new Error("Unable to parse date " + dateString);
      }
      let hour = includeTime ? utc ? date.getUTCHours() : date.getHours() : 0;
      let minute = includeTime ? utc ? date.getUTCMinutes() : date.getMinutes() : 0;
      let second = includeTime ? utc ? date.getUTCSeconds() : date.getSeconds() : 0;

      return {
        year: utc ? date.getUTCFullYear() : date.getFullYear(),
        month: utc ? date.getUTCMonth() + 1 : date.getMonth() + 1, // oh you, javascript.
        day: utc ? date.getUTCDate() : date.getDate(),
        hour: hour > 10 ? hour : '0' + hour.toString(),
        minute: minute > 10 ? minute : '0' + minute.toString(),
        second: second > 10 ? second : '0' + second.toString(),
      };
    },
    toDateString: function (date) {
      return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    },
    utcToZonedTime: function (date, _format, token) {
      const timezone = token && token.menu ? token.menu.ApplicationTz : moment.tz.guess();
      var format = token && token.menu ? token.menu.ApplicationTzFromat : 'MM/DD/yyyy h:mm a';
      format = _format ? _format : format
      if (date && typeof date === 'string' && date.includes('T')) {
        if (!date.includes('Z') && !date.includes('+')) {
          date = date + 'Z';
        }
        if (timezone) {
          const date_local = moment.tz(date, timezone);
          // const date_local = moment.utc(moment(date).format()).local().format('YYYY-MM-DD HH:mm:ss');
          // const timezoneOffset = moment(date_local).tz(timezone);

          return date_local.format(format);
        } else {
          var localTime = moment(date).toDate();

          return moment(localTime).format(format);
        }
      } else {
        return moment(date).format(format);
      }
    },
    camelize: function (str) {
      return str.split(' ').map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join('');
    },
    getTwentyFourHourTime: function (amPmString) {
      var d = new Date("1/1/2013 " + amPmString);
      return d.getHours() + ':' + d.getMinutes();
    },
    groupByWithDefault: (jsondata, key) => {
      if (jsondata && Array.isArray(jsondata) && jsondata.length > 0) {
        return jsondata.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      } else {
        return { 'undefined': [] };
      }
    },
    groupBy: (jsondata, key) => {
      if (jsondata && Array.isArray(jsondata)) {
        return jsondata.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      } else {
        return {};
      }
    },
    dataURItoBlob: (dataURI) => {
      // Split metadata from data
      const splitted = dataURI.split(",");
      // Split params
      const params = splitted[0].split(";");
      // Get mime-type from params
      const type = params[0].replace("data:", "");
      // Filter the name property from params
      const properties = params.filter(param => {
        return param.split("=")[0] === "name";
      });
      // Look for the name and use unknown if no name property.
      let name;
      if (properties.length !== 1) {
        name = "unknown";
      } else {
        // Because we filtered out the other property,
        // we only have the name case here.
        name = properties[0].split("=")[1];
      }

      // Built the Uint8Array Blob parameter from the base64 string.
      const binary = atob(splitted[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      // Create the blob object
      const blob = new window.Blob([new Uint8Array(array)], { type });

      return { blob, name };
    },
    sortArrayDesc: (array, key) => {
      if (Array.isArray(array)) {
        return array.sort(function (a, b) {
          return (b[key] > a[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0)
        })
      } else return JSON.parse("[]");
    },
    isEmptyorNull: (value) => {
      let isEmpty = false;
      if (value === null || value === undefined || value === '' || (typeof value == 'string' && value.trim() === '')) {
        isEmpty = true;
      }
      return isEmpty;
    },
    loop: (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.Url === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          ControlService().loop(item.children, key, callback);
        }
      });
    },
    isEmail: (text) => {
      // eslint-disable-next-line 
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return reg.test(text);
    },
    isPhoneNumber: (text) => {
      return isPhoneNumber(text);
    },
    isWebAddress: (text) => {
      return isWebAddress(text);
    },
    updateGroup: (token, data, isApplication = false) => {
      var apiparam = {}
      var control = {}
      if (!data.id) {
        data.id = uuidv4();
      }
      if (token.loginMethod) {
        control.method = settings.METHOD.PUT;
        control.model = settings.WORKBENCH.GROUP;
        control.datasource = ControlService().loggedInDataSource(token, isApplication);
      } else {
        control.method = "post";
        control.headers = null;
        control.datasource = "http";
      }
      apiparam.idx = data.database;
      return ControlService().ServiceRequest("", apiparam, control, token, data);
    },
    loadSecretKey: async (Key, control, token) => {
      var res = await ControlService().ServiceRequest(settings.KEY_VAULT_API, { id: Key }, control, token, null, null);
      debugger;
      let data = res && res.data ? res.data : {};
      data = JSON.parse(data);
      return data[Key] ? JSON.parse(data[Key]) : null;
    },
    loadMetadata: async (formid, token, idx = null, disablecache = false, workbench = false) => {
      let _apiparam = {};
      if (formid) {
        if (!disablecache && (MetadataStream[formid] || (token.ncameo && token.ncameo[formid]))) {
          if (MetadataStream[formid])
            return MetadataStream[formid];
          else
            return Promise.resolve(token.ncameo[formid]);
        }
        else if (token.loginMethod && idx != 'data_metadataquickforms' && settings.excludedFormId.indexOf(formid) < 0) {
          var control = {}
          var model = {}
          control.method = settings.METHOD.GET;
          control.model = formid
          control.datasource = loggedInDataSource(token, !token.enablegit)
          return utilily().servicerequest(control, null, _apiparam, model, token).then(response => {

            deserializeForm(response);
            let request = Promise.resolve(response);
            MetadataStream[formid] = request;
            return request;
          });
        } else {
          _apiparam.id = formid;
          if (idx || (token.user && token.user.ActiveApp)) {
            _apiparam.idx = idx ? idx : token.user.ActiveApp.database;
          }
          const _control = {
            datasource: 'http',
            method: 'get'
          }
          let url = settings.APP_FORM_API;
          if (idx == 'data_metadataquickforms') {
            url = settings.TEMPLATE_API_URL + '/' + url;
          }
          let request = utilily().servicerequest(_control, url, _apiparam, null, token);
          MetadataStream[formid] = request;
          return request;
        }
      } else {
        return Promise.resolve({ status: "error", message: "No data" });
      }
    },
    loadModelData: (ApiUrl, ApiParams, token, data, advancesearchapimapping) => {
      let _apiparam = {};
      var url = ApiUrl;
      if (Array.isArray(ApiParams)) {
        ApiParams.forEach(option => {
          _apiparam[option.key] = option.value;
        });
      } else {
        _apiparam = ApiParams
      }
      if (advancesearchapimapping && Array.isArray(advancesearchapimapping)) {
        advancesearchapimapping.forEach(option => {
          _apiparam[option.ApiParam] = data[option.advancesearchfield];
        })
      }

      _apiparam = setPageVariables(_apiparam);

      const control = {
        datasource: 'http',
        method: 'get'
      }
      // return Network().get(url, _apiparam, token);
      return utilily().servicerequest(control, url, _apiparam, null, token);
    },
    loadModelDataPost: (ApiUrl, ApiParams, body, token) => {
      let _apiparam = {};
      var url = ApiUrl;
      if (url) {
        ApiParams.forEach(option => {
          _apiparam[option.key] = option.value;
        });

        _apiparam = setPageVariables(_apiparam);

        const control = {
          datasource: 'http',
          method: 'post'
        }
        // return Network().post(url, JSON.stringify(body), token, _apiparam);
        return utilily().servicerequest(control, url, _apiparam, JSON.stringify(body), token);
      }
    },
    isTokenValid: (token) => {
      return _isTokenValid(token);
    },
    isTokenValid5Minutes: (token) => {
      return token && token.expiresOn ? new Date(token.expiresOn).getTime() < ((new Date().getTime()) + 5 * 60000) : false;
    },
    ServiceRequest: (ApiUrl, ApiParams, control, token, model, pageparams, advancesearchapimapping, callback, props) => {
      return ServiceRequest(ApiUrl, ApiParams, control, token, model ? model : {}, pageparams, advancesearchapimapping, callback, props);
    },
    ConvertToModelData: (data, ModelJson) => {
      if (data["id"] === "")
        delete data["id"];

      if (Array.isArray(data))
        return data.length > 0 ? data[0] : ModelJson;
      else if (data)
        return data;
      else
        return ModelJson;
    },
    setRolesApi: (controls, key) => {
      let item = controls.find(x => x.key === key);
      if (item) {
        let index = controls.indexOf(item);
        controls[index].ResultApi = settings.ROLE_LIST_API;
      }
    },
    renderControls: (control, action, formid) => {
      if (!control.filterresult) {
        return (
          <Suspense fallback={<Loading enable={true} type="child" />}>
            <DynamicFormControls key={control.id} formid={formid} control={control} action={action}
              className={"col-md-" + ' ' + control.width + ' ' + control.colclass} />
          </Suspense>
        );
      } else {
        return null;
      }
    },
    formatResult: (data) => {
      var Datalist = [];
      data.forEach((item) => {
        var row = {};

        for (var key in item) {
          if (item.hasOwnProperty(key)) {

            if (typeof item[key] === 'object') {
              var item2 = item[key];
              for (var key2 in item2) {
                if (item2.hasOwnProperty(key2)) {
                  row[key + "~" + key2] = item2[key2];
                }
              }

            } else {
              row[key] = item[key];
            }

          }
        }
        Datalist.push(row);
      });
      return Datalist;
    },

    setMappingFields: (control) => {
      var FieldsMapping = {};
      var arr = {};
      var mappingFields = [];
      var highlighter = [];

      control.ResultDisplayField.filter(x => !x.hideoncard).forEach(item => {
        if (item.fieldtype === 'title') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          arr.isDate = item.isDate;
          arr.dateformat = item.dateformat;
          FieldsMapping.title = arr;
        } else if (item.fieldtype === 'description') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          arr.isDate = item.isDate;
          arr.dateformat = item.dateformat;
          FieldsMapping.description = arr;
        } else if (item.fieldtype === 'subtitle') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          arr.isDate = item.isDate;
          arr.dateformat = item.dateformat;
          FieldsMapping.subtitle = arr;
        } else if (item.fieldtype === 'email') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.email = arr;
        } else if (item.fieldtype === 'phone') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.phone = arr;
        } else if (item.fieldtype === 'website') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.website = arr;
        } else if (item.fieldtype === 'address') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.address = arr;
        } else if (item.fieldtype === 'headerimage') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.headerimage = arr;
        } else if (item.fieldtype === 'header') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.header = arr;
        } else if (item.fieldtype === 'sellprice') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.sellprice = arr;
        } else if (item.fieldtype === 'price') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.price = arr;
        } else if (item.fieldtype === 'offer') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.offer = arr;
        } else if (item.fieldtype === 'totalstars') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.totalstars = arr;
        } else if (item.fieldtype === 'rating') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.rating = arr;
        } else if (item.fieldtype === 'selectedstars') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.selectedstars = arr;
        } else if (item.fieldtype === 'image') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.image = arr;
        } else if (item.fieldtype === 'highlight') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.infohighlighterprop = item.infohighlighterprop;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.highlight = arr;
        } else if (item.enablehighlighter) {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.highlighterprop = item.highlighterprop;
          arr.oneline = item.oneline;
          arr.color = item.color;
          highlighter.push(arr);
        } else if (item.fieldtype === 'skill') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.skill = arr;
        } else if (item.fieldtype === 'html') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.color = item.color;
          FieldsMapping.html = arr;
        } else if (item.fieldtype === 'bordercolor') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          FieldsMapping.bordercolor = arr;
        } else if (item.fieldtype === 'tag') {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          arr.tagbg = item.tagbg;
          arr.tagcolor = item.tagcolor;
          FieldsMapping.tag = arr;
        } else {
          arr = {};
          arr.key = item.DisplayName;
          arr.value = item.BindingField;
          arr.fieldtype = item.fieldtype;
          arr.redirecturl = item.RedirectParam ? control.redirectOptions.formid : null;
          arr.redirectparam = item.TargetField;
          arr.webnametype = item.webnametype;
          arr.webicon = item.WebIcon;
          arr.oneline = item.oneline;
          arr.color = item.color;
          mappingFields.push(arr);
        }
      });
      FieldsMapping.mappingFields = mappingFields;
      FieldsMapping.highlighter = highlighter;

      return FieldsMapping;
    },
    setParam: (action, ModelData, menu) => {
      var _index = 0;
      var redirectUrl = null;
      if (action.RedirectUrl || action.bookmarkurl) {
        redirectUrl = action.RedirectUrl ? action.RedirectUrl : action.bookmarkurl;
      } else if (action.buttonredirecturl) {
        redirectUrl = action.buttonredirecturl;
      } else {
        redirectUrl = window.location.pathname;
        if (redirectUrl.indexOf('?') > -1) {
          redirectUrl = redirectUrl.split('?')[0];
        }
      }

      if (redirectUrl) {
        var arr = redirectUrl.split('');
        arr.forEach((char, i) => {
          if (/^[a-zA-Z]*$/g.test(char) && _index == 0) {
            _index = i;
            return false;
          } else {
            return true;
          }
        });
      }
      if (menu) {
        if (redirectUrl == "../story/summary") {
          _index = 0;
        }
        var page = find(menu['Nav'], redirectUrl.substring(_index));
        if (page && (action.type == 'bookmark' || action.actiontype == 'popup' || action.actiontype == 'sidebar' || action.ActionType == 'sidebar' || action.ActionType == 'popup' || action.ActionType == 'attachwithformwizard' || action.setdatatopagevariable)) {
          ControlService().loop(menu['Nav'], redirectUrl.substring(_index), (item, index, _arr) => {
            item.Params.forEach(_item => {
              if (action.setdatatopagevariable && _item.name == action.datatopagevariable) {
                let result = null;
                if (action.datatopagevariabletype === 'object') {
                  result = ModelData;
                } else if (action.datatopagevariabletype === 'array') {
                  let _ModelData = ControlService().deepcopy(ModelData);
                  delete _ModelData['cameoCardUniqueKey'];
                  delete _ModelData['__firstreocrd'];
                  result = _.map(_ModelData, (value, prop) => ({ [action.modelbindingkey ? action.modelbindingkey : 'key']: prop, [action.modelbindingvalue ? action.modelbindingvalue : 'value']: value }));
                }
                _item.value = result ? result : {};
              } else if (_item.name == action.PrimaryKey) {
                _item.value = ModelData[action.PrimaryKey];
              } else if (_item.attribute) {
                _item.value = ModelData[_item.attribute];

              } else if (ModelData[_item.name] || ModelData[_item.name] == false) {
                _item.value = ModelData[_item.name];
              } else if (GetParamUrl(_item.name)) {
                _item.value = GetParamUrl(_item.name);
              }
            });
          });
        } else {
          var currentUrl = window.location.pathname;
          if (currentUrl.indexOf('?') > -1) {
            currentUrl = currentUrl.split('?')[0];
          }
          var currentPage = find(menu['Nav'], currentUrl.substring(1));//this._controlService.deepcopy(menu).Nav.find(x => x.Url == currentUrl.substring(1));
          if (currentPage) {
            ControlService().loop(menu['Nav'], redirectUrl.substring(1), (item, index, _arr) => {
              currentPage.Params.forEach(_item => {
                arr = {}
                arr.name = _item.name;
                if (_item.name == action.PrimaryKey) {
                  arr.value = ModelData[_item.name];
                } else if (_item.attribute && ModelData[_item.attribute]) {
                  arr.value = ModelData[_item.attribute];
                } else if (_item.name && ModelData[_item.name]) {
                  arr.value = ModelData[_item.name];
                } else if (GetParamUrl(_item.name)) {
                  arr.value = GetParamUrl(_item.name);
                }

                let _param = item.Params.find(x => x.name == _item.name);
                if (_param) {
                  _param.value = arr.value;
                } else if (Object.hasOwn(arr, 'value')) {
                  item.Params.push(arr);
                }
              });
            });
          }
        }
      }
      return menu;
    },

    GenerateDataJson: (control, datamodel, formid, forcereset, props) => {
      GenerateDataJson(control, datamodel, formid, forcereset);
      if (props && props.setDirtyFormModel) {
        debugger;
        props.setDirtyFormModel(formid, datamodel);
      }
    },
    validate: (control, data) => {
      let isValidate = true;
      if (control.require === true) {
        if (Array.isArray(data)) {
          if (data.length > 0) {
            if (control.minimumselect > 0) {
              if (data.length < control.minimumselect) {
                isValidate = false;
              }
            } else if (control.maximumselect > 0) {
              if (data.length > control.maximumselect) {
                isValidate = false;
              }
            }
          } else {
            isValidate = false;
          }
        } else if (control.type === "email" && data && !data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          isValidate = false;
        } else if (control.type === "phonenumber" && data && !isValidPhoneNumber(data)) {
          isValidate = false;
        } else if (control.type === "website" && data && !isWebAddress(data)) {
          isValidate = false;
        } else if (control.type === "checkbox" && data == false) {
          isValidate = false;
        } else if (control.charlimit > 0 && data) {
          if (data.length > control.charlimit || data.length === 0) {
            isValidate = false;
          }
        } else if (control.Textlimit > 0 && data) {
          if (data.length > control.Textlimit || data.length === 0) {
            isValidate = false;
          }
        }
        else if (data == undefined || data.toString() == "" || data == {} || data == null) {
          isValidate = false;
        }
      }
      else if (control.type === "email" && data && !data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        isValidate = false;
      } else if (control.type === "phonenumber" && data && !isValidPhoneNumber(data)) {
        isValidate = false;
      } else if (control.type === "website" && data && !isWebAddress(data)) {
        isValidate = false;
      } else if (control.charlimit > 0 && data) {
        if (data.length > control.charlimit || data.length === 0) {
          isValidate = false;
        }
      }

      return isValidate;
    },
    SetParamUrl: (_k, _v) => {// replace and add new parameters
      SetParamUrl(_k, _v)
    },
    find: (source, url, keyValue, ignoreType) => {
      return find(source, url, keyValue, ignoreType);
    },
    GetParamUrl: (_k) => {// get parameter by key
      return GetParamUrl(_k);
    },
    setPageVariables: (apiparam) => {
      return setPageVariables(apiparam);
    },
    RefreshBindingControls: (controlKey, bindingControls, props, isInitialize) => {
      bindingControls.forEach(control => {
        let _control = JSON.parse(JSON.stringify(control));
        if (control.actiontype === "filter") {
          // const data = props && props.data[formid] ? props.data[formid] : {};
          // const currentdata = currentProps && currentProps.data[formid] ? currentProps.data[formid] : {};
          // if (data[controlKey] !== currentdata[controlKey] || !isInitialize) {
          _control.sourcecontrolkey = controlKey;
          _control.id = uuidv4();
          (props && props.updateParent) && props.updateParent(props.formid + control.controlkey, _control);
          if (props && !control.stopReset && !isInitialize) {
            props.update(control.controlkey, "", props.formid);
          }
        }
        else if (control.actiontype === "populate") {
          let value = props.data[props.formid] ? props.data[props.formid][props.control.key] : {};
          var selctedOption = props.control.optionlist.find(x => x.value === value);

          props.update(control.controlkey, selctedOption && selctedOption.populatejson !== '' ? JSON.parse(selctedOption.populatejson) : [], props.formid);
        } else if (control.actiontype === "refresh") {
          if (Array.isArray(control.refreshcomponentsonchange) && control.refreshcomponentsonchange.length > 0) {
            control.refreshcomponentsonchange.forEach(refreshcomponent => {
              let _control = {
                refreshcomponentonclose: refreshcomponent,
                refreshcomponenformid: control.refreshcomponenformid
              }
              import('../../forms/Button/_private').then(({ RefreshComponentOnClose }) => {
                RefreshComponentOnClose(_control, props.formid, props);
              });
            })
          }
        }
      })
    },
    deepcopy: (jsno) => {
      try {
        return JSON.parse(JSON.stringify(jsno));
      } catch (e) {
        return jsno;
      }
    },
    generateKeywords: object => {

      const createKeywords = name => {
        const arrName = [];
        let curName = '';
        name.split('').forEach(letter => {
          curName += letter;
          arrName.push(curName);
        });
        return arrName;
      }

      const keys = Object.keys(object).filter(key => key.indexOf('__') < 0 && key.toLowerCase().indexOf('id') < 0);
      var keywords = [];
      keys.forEach(key => {
        var isDate = new Date(object[key]).toString() === 'Invalid Date' ? false : true;
        if (typeof object[key] === 'string' && object[key] && !isDate && !isPhoneNumber(object[key]) && isNaN(object[key]) && !isGuid(object[key])) {
          var str = object[key].length > 20 ? object[key].substring(0, 20) : object[key];
          keywords[key] = createKeywords(str.toLowerCase());
        }
      })
      var output = [];
      for (let item in keywords) {
        output.push(...keywords[item]);
      }
      output.unshift('');
      return [
        ...new Set(output)
      ];
    },
    loggedInDataSource: (token, application) => {
      return loggedInDataSource(token, application);
    },
    createFacetRequst: (data, control, selectedFacet) => {
      let dataModel = {
        "search": "*",
        "facets": [],
        "searchFields": "",
        "orderby": "",
        "orderbyField": "",
        "select": "",
        "filter_gender": "male,female"
      };
      if (data[control.search]) {
        dataModel.search = data[control.search];
      }
      if (Array.isArray(control.searchFields)) {
        dataModel.searchFields = control.searchFields.join(",");
      }
      if (Array.isArray(control.orderby)) {
        dataModel.orderby = control.orderby;
      }
      if (Array.isArray(control.orderbyFields)) {
        dataModel.orderbyFields = control.orderbyFields.join(",");
      }
      if (Array.isArray(control.select)) {
        dataModel.select = control.select.join(",");
      }
      if (Array.isArray(control.facet)) {
        control.facet.map(x => {
          let arr = {
            "name": x.key,
            "type": x.type,
            "operator": x.operator,
          };
          if (selectedFacet) {
            if (Array.isArray(selectedFacet[x.key])) {
              if (selectedFacet[x.key].length > 0) {
                arr.value = selectedFacet[x.key];
              }
            } else if (selectedFacet[x.key]) {
              arr.value = selectedFacet[x.key];
            }
          }
          dataModel.facets.push(arr);
        });
      }
      return dataModel;
    },
    buildRedirectUrl: (action) => {
      var _index = -1;
      var redirectUrl = action.RedirectUrl;

      if (redirectUrl) {
        var arr = redirectUrl.split('');
        arr.forEach((char, i) => {
          if (/^[a-zA-Z]*$/g.test(char) && _index === -1) {
            _index = i;
            return false;
          } else {
            return true;
          }
        });
        if (_index === -1) {
          _index = 0;
        }
        return redirectUrl.substring(_index)
      } else {
        return redirectUrl;
      }
    },
    isbookmarked: (action, props) => {
      // return props.Bookmark.find(x => x.id == this.BookmarkId) ? true : false;
      var isbookmarked = false;
      const currentUrl = action.RedirectUrl;
      // const menu = this.auth.Menu;
      const bookmark = props.Bookmark ? props.Bookmark.filter(x => x.url == currentUrl.substring(1)) : [];
      // const page = this._controlService.find(menu['Nav'], currentUrl.substring(1));
      if (bookmark) {
        bookmark.forEach(item => {
          const param = item.params.find(x => x.name === action.PrimaryKey && x.value == props.data[action.PrimaryKey]);
          if (param) {
            isbookmarked = true;
          }
        })
      }
      return isbookmarked;
    },
    customAction: async (action, props, context) => {
      var BookmarkId = null;
      var isApiCalling = false;
      if (window.location.pathname == '/workbench') {
        if (props.data && props.data.__firstreocrd) {
          return { isApiCalling, BookmarkId };
        } else {
          toast.info(<Message icon={"fa fa-exclamation-triangle"} title={"Hello!"} message={"The action doesn't work from workbench, Please goto navigation URL to test."} />)
          return { isApiCalling, BookmarkId };
        }
      }
      var { token } = props;
      var menu = ControlService().setParam(action, props.data, ControlService().deepcopy(token.menu));
      token.menu = menu;
      props.authSetToken(token);
      props.setPageParams(props.data);
      if (action.ActionType === 'attachwithformwizard') {
        props.update(action.formwizardkey + '_next', uuidv4(), action.sidebarformid ? (action.sidebarformid + 'write') : props.formid);
      } else if (action.ActionType === 'splitview') {
        props.resetSidebar(false);
        props.setGUID(uuidv4());
        SetParamUrl(action.primarykeynode ? action.primarykeynode : action.PrimaryKey, props.data[action.PrimaryKey]);
        if (action.sidebarformidfield) {
          props.setSplitViewFormId(props.data[action.sidebarformidfield]);
        } else {
          props.setSplitViewFormId(action.sidebarformid);
        }
      }
      else if (action.ActionType === 'sidebar') {
        SetParamUrl(action.primarykeynode ? action.primarykeynode : action.PrimaryKey, props.data[action.PrimaryKey]);
        props.resetSidebar(!props.isSidebarOpened);
        if (action.sidebarformidfield) {
          props.setSidebarFormId(props.data[action.sidebarformidfield]);
        } else {
          props.setSidebarFormId(action.sidebarformid);
        }
      } else if (action.ActionType === 'bookmarkinaction') {
        BookmarkId = addbookmark(action, props);
      } else if (action.ActionType === 'popup') {
        SetParamUrl(action.primarykeynode ? action.primarykeynode : action.PrimaryKey, props.data[action.PrimaryKey]);

        props.resetModelPopup(!props.isModelOpened);
        props.setModelFormId(action.sidebarformid);
      } else if (action.ActionType === 'submit' || action.ActionType === 'submitredir' || action.ActionType === 'submitredirNew'
        || action.ActionType === 'submitredirboth' || action.ActionType === 'submitredirNewWindow'
        || action.ActionType === 'submitbookamrk' || action.ActionType === 'submitsidebar') {
        if (props.control && props.control.ViewType != 'splitview') {
          props.dataIsLoading(true);
        }
        isApiCalling = await submitAction(action, props, context);
      } else if (action.ActionType === 'sametab' || action.ActionType === 'newtab' || action.ActionType === 'newwindow') {
        let qs = (action.primarykeynode ? action.primarykeynode : action.PrimaryKey) + '=' + props.data[action.PrimaryKey];
        let RedirectUrl = props.data[action.RedirectUrl] ? props.data[action.RedirectUrl] : action.RedirectUrl;

        let path = RedirectUrl;
        if (action.PrimaryKey) {
          path = RedirectUrl + "?" + qs;
        }
        if (action.ActionType === 'sametab') {
          if (props.data[action.RedirectUrl]) {
            window.open(path, 'self');
          } else {
            context.history.push(path);
          }
        } else if (action.ActionType === 'newtab') {
          window.open(path, '_blank');
        } else if (action.ActionType === 'newwindow') {
          window.open(path, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=100,width=1200,height=700')
        }
      }
      return { isApiCalling, BookmarkId }
    },
    extractControl: (control, _prefix) => {
      let _control = ControlService().deepcopy(control);
      const prefix = _prefix == 'filter' ? '' : _prefix;

      _control.Api = _prefix == 'filter' ? control['FilterApiEndpoint'] : control[prefix + 'Api'];
      _control.connection = control[prefix + 'connection'];
      _control.datasource = control[prefix + 'datasource'];
      _control.datasourcetype = control[prefix + 'datasourcetype'];
      _control.enablerowquery = control[prefix + 'enablerowquery'];
      _control.function = control[prefix + 'function'];
      _control.headers = control[prefix + 'headers'];
      _control.method = control[prefix + 'method'];
      _control.model = control[prefix + 'model'];
      _control.paramlist = _prefix == 'filter' ? control['FilterEndpointParams'] : control[prefix + 'paramlist'];
      _control.apiparam = _prefix == 'filter' ? control['FilterEndpointParams'] : control[prefix + 'paramlist'];
      _control.payload = control[prefix + 'payload'];
      _control.query = control[prefix + 'query'];
      _control.responsefield = control[prefix + 'responsefield'];
      _control.rowquery = _prefix == 'filter' ? control['FilterRawQuery'] : control[prefix + 'rowquery'];
      _control.sprocname = _prefix == 'filter' ? control['filtersprocname'] : control[prefix + 'sprocname'];
      _control.workflowname = control[prefix + 'workflowname'];
      return _control;
    },
    controlVaildationCheck: (props) => {
      const { control, formid, data } = props;
      var _data = data[formid] ? data[formid][control.key] : null
      var validation = ControlService().validate(control, _data);
      if (validation) {
        props.update(control.key, { "status": "pass", "control": control, touched: false }, props.formid + "_validation")
      } else {
        props.update(control.key, { "status": "fail", "control": control, touched: false }, props.formid + "_validation")
      }
    },
    encryptData: (model) => {
      if (typeof model == 'string' && model.length > 0) {
        model = EncryptionService(JSON.parse(model), settings.APP_ENCRYPTION_KEY).encryptData();
      } else if (typeof model == 'object' && Object.keys(model).length > 0) {
        model = EncryptionService(model, settings.APP_ENCRYPTION_KEY).encryptData();
      }
      return model;
    },
    decryptData: (model) => {
      if (typeof model == 'string' && model.length > 0) {
        model = EncryptionService(model, settings.APP_ENCRYPTION_KEY).decryptData();
      } else if (typeof model == 'object' && Object.keys(model).length > 0) {
        model = EncryptionService(JSON.stringify(model), settings.APP_ENCRYPTION_KEY).decryptData();
      }
      return model;
    }
  }
};

export default ControlService;
